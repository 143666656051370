<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="公告标题">
                <a-input v-model="queryParam.noticeTitle" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'" />-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
<!--      <div class="table-operations">
        <a-button type="primary" @click="handleAdd()" v-hasPermi="['system:notice:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="handleUpdate(undefined, ids)"
          v-hasPermi="['system:notice:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:notice:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
          :refresh-loading="loading" @refresh="getList" />
      </div>-->
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <span slot="noticeType" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_type']" :value="record.noticeType" />
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.status === '1' ? '0' : '1'" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleUpdate(record, undefined)" v-hasPermi="['system:notice:edit']">
            <a-icon type="edit" />{{$t('通用.按钮.详情')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['system:notice:remove']" />
<!--          <a @click="handleDelete(record)" v-hasPermi="['system:notice:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { pageNotice, delNotice } from '@/api/system/notice'
import { tableMixin } from '@/store/table-mixin'
import bus from '@/utils/bus'

export default {
  name: 'Notice',
  components: {
  },
  mixins: [tableMixin],
  dicts: ['sys_notice_status', 'sys_notice_type'],
  data() {
    return {
      /**
       *
      dict.type['sys_notice_type']: [{
        label: '正常',
        value: '1'
      }, {
        label: '停用',
        value: '0'
      }]
       */
      statusOptions: [
        {
          label: '正常',
          value: '0'
        }, {
          label: '停用',
          value: '1'
        }
      ],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        createBy: undefined,
        status: undefined
      },
      columns: [
        {
          title: '公告编号',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '公告标题',
          dataIndex: 'noticeTitle',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公告类型',
          dataIndex: 'noticeType',
          scopedSlots: { customRender: 'noticeType' },
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
       /* {
          title: '操作人员',
          dataIndex: 'creator',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
    /**编辑页面接收到更新通知消息的数据 */
    bus.$on('updateNoticeList',()=>{
      this.getList()
    })
  },
  computed: {
  },
  methods: {
    /** 查询公告列表 */
    getList() {
      this.loading = true
      pageNotice(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      }
      )
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        createBy: undefined,
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const noticeIds = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + noticeIds + '的数据',
        onOk() {
          return delNotice(noticeIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({
        name: 'NoticeFormkf',
        params:
        {
          id: undefined,
          formTitle: '添加公告'
        }
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      const noticeId = row ? row.id : ids
      this.$router.push({
        name: 'NoticeFormkf',
        params:
        {
          id: noticeId,
          formTitle: '公告详情'
        }
      })
    }
  }
}
</script>
